// extracted by mini-css-extract-plugin
export var headerSection = "aboutus-module--headerSection--GCFwe";
export var darkOverlay = "aboutus-module--darkOverlay--BapTB";
export var headerText = "aboutus-module--headerText--PHFd8";
export var lightHl = "aboutus-module--lightHl--A0oZr";
export var boldHl = "aboutus-module--boldHl--U2xIf";
export var infoSection = "aboutus-module--infoSection--SueH9";
export var infoRow = "aboutus-module--infoRow--w99rx";
export var textSection = "aboutus-module--textSection--ctSRL";
export var subtitle = "aboutus-module--subtitle--M6AMg";
export var description = "aboutus-module--description--bywnj";
export var iconSection = "aboutus-module--iconSection--wPF4s";
export var icon = "aboutus-module--icon--fvVel";
export var productFacts = "aboutus-module--productFacts--SSBcl";
export var ingredientsSection = "aboutus-module--ingredientsSection--sbLM6";
export var stickyIngredients = "aboutus-module--stickyIngredients--KLCrs";
export var ingredientsText = "aboutus-module--ingredientsText--F5TQB";
export var listRow = "aboutus-module--listRow--KqCFn";
export var listHl = "aboutus-module--listHl--zhyNY";
export var sustainability = "aboutus-module--sustainability--yI89Q";
export var keyPoints = "aboutus-module--keyPoints--NHNnS";
export var keyPoint = "aboutus-module--keyPoint--Qab4T";
export var keyIcon = "aboutus-module--keyIcon--kWENy";
export var dotContainer = "aboutus-module--dot-container--OQRh3";
export var greenDot = "aboutus-module--green-dot--aDvJq";
export var teamSection = "aboutus-module--teamSection--HdL1c";
export var memberSection = "aboutus-module--memberSection--iE4aB";
export var imageDiv = "aboutus-module--imageDiv--Zgn6Y";
export var memberImage = "aboutus-module--memberImage--BqYPW";
export var memberName = "aboutus-module--memberName--6md92";
export var memberTask = "aboutus-module--memberTask--w9X3I";
export var imgSubtitle = "aboutus-module--imgSubtitle--BBhiO";
export var mobileHeader = "aboutus-module--mobileHeader--ln2uZ";
export var infoImg = "aboutus-module--infoImg--0HRfp";
export var sustainabilityGif = "aboutus-module--sustainabilityGif--c5jnt";