import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../../components/layout/layout"

import * as styles from "./aboutus.module.scss"
import deliveryGIF from '../../images/GIF_Versandumschlag.gif'

import IconSection from "../../components/index/iconSection"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Seo from "../../components/layout/seo";

export default function AboutUs() {

  const data = useStaticQuery(graphql`
    query {
      backgroundImg: file(relativePath: { eq: "aboutus_bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      },
      backgroundImgMobile: file(relativePath: { eq: "aboutus_bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 450, width: 375, placeholder: NONE)
        }
      },
      productionImg: file(relativePath: { eq: "dropsproduction.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      },
      plasticImg: file(relativePath: { eq: "aboutus_waste.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      },
      moneyIcon: file(relativePath: { eq: "money.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      },
      smileyIcon: file(relativePath: { eq: "smiley.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      },
      xylithImg: file(relativePath: { eq: "xylitol.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      },
      mintImg: file(relativePath: { eq: "mint.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      },
      natronImg: file(relativePath: { eq: "natron.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      },
      ccImg: file(relativePath: { eq: "cc.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      },
      guakaImg: file(relativePath: { eq: "guaka.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      },
      mentholImg: file(relativePath: { eq: "menthol.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      },
      steviaImg: file(relativePath: { eq: "stevia.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      },
      mccImg: file(relativePath: { eq: "mcc.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      },
      slgImg: file(relativePath: { eq: "slg.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      },
      sioImg: file(relativePath: { eq: "sio.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      },
      fluoridImg: file(relativePath: { eq: "fluorid.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      },
      team: file(relativePath: { eq: "drops_team.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      },
    }
  `)

  return (
    <Layout showBottom="false">
      <Seo title="Drops | About Us | Toothpaste Drops" path="/pages/aboutus" description="Über 5000 zufriedene Kunden - Natürlich - Plastikfrei - Ohne Tierversuche - Vegan - Zahnpasta Drops. Hilf uns den Plastikmüll in unserer Umwelt & den Meeren zu beenden. #weltretter" />
      <div className={styles.headerSection}>
        <GatsbyImage className="d-none d-md-block" image={data.backgroundImg.childImageSharp.gatsbyImageData} alt="About Us" />
        <GatsbyImage className={`d-block d-md-none ${styles.mobileHeader}`} imgClassName={styles.mobileHeader} style={{objectFit: 'cover !important'}} objectFit="cover" image={data.backgroundImgMobile.childImageSharp.gatsbyImageData} alt="About Us" />
        <div className={styles.darkOverlay}></div>
        <div className={styles.headerText}>
          <p className={`${styles.lightHl} px-3`}>Wir helfen dir, Plastik aus dem Bad zu verbannen:</p>
          <p className={`${styles.boldHl} px-3`}>Ohne Kompromisse, mit gutem Gewissen.</p>
        </div>
      </div>
      <div className={styles.infoSection}>
        <Container>
          <Row className={styles.infoRow}>
            <Col lg={6}>
              <GatsbyImage image={data.plasticImg.childImageSharp.gatsbyImageData} className="d-none d-lg-block" alt="Plastik muss vermieden werden" />
            </Col>
            <Col lg={6} className={styles.textSection}>
              <h2>Warum?</h2>
              <p className={styles.subtitle}>Warum Plastiktuben, warum Mikroplastik und warum ist Nachhaltigkeit immer so teuer?</p>
              <GatsbyImage image={data.plasticImg.childImageSharp.gatsbyImageData} className={`${styles.infoImg} d-block d-lg-none`} alt="Plastik muss vermieden werden" />
              <p className={styles.description}>Den Planeten zu retten, das klingt immer sehr groß und mächtig. Im Herzen von Drops möchten wir, dass man sich klar macht, dass auch kleine Taten in der großen Masse einen Unterschied machen können. Es gibt viele Möglichkeiten Plastik einzusparen, doch wir sind der festen Überzeugung, dass wenn man mit einem alltäglichem Produkt, wie der Zahnpasta beginnt, man automatisch und sehr einfach ein Bewusstsein für ein nachhaltigeres Leben schaffen kann.</p>
            </Col>
          </Row>
          <Row className={styles.infoRow}>
            <Col lg={6} className={styles.textSection}>
              <h2>Unsere Mission</h2>
              <p className={styles.subtitle}>Der erste Schritt zur Nachhaltigkeit im eigenen Leben</p>
              <GatsbyImage image={data.productionImg.childImageSharp.gatsbyImageData} className={`${styles.infoImg} d-block d-lg-none`} alt="Nachhaltigkeit darf nicht teuer sein" />
              <div className={styles.iconSection}>
                <div className={styles.icon}>
                  <GatsbyImage image={data.moneyIcon.childImageSharp.gatsbyImageData} alt="Nachhaltigkeit muss nicht teuer sein" />
                </div>
                <p><span className="fw-bold">Nachhaltigkeit darf nicht teuer sein.</span> Der Griff zur Plastiktube passiert zuerst im Geldbeutel. Wie soll sonst der Umbruch passieren?</p>
              </div>
              <div className={styles.iconSection}>
                <div className={styles.icon}>
                  <GatsbyImage image={data.smileyIcon.childImageSharp.gatsbyImageData} alt="Nachhaltigkeit muss ohne Kompromisse gehen" />
                </div>
                <p><span className="fw-bold">Ohne Kompromisse.</span> Das nachhaltige Produkt muss mindestens genauso gut sein in Anwendung, Geschmack, Geruch und Gefühl.</p>
              </div>
            </Col>
            <Col lg={6}>
              <GatsbyImage image={data.productionImg.childImageSharp.gatsbyImageData} className="d-none d-lg-block" alt="Nachhaltigkeit darf nicht teuer sein" />
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.productFacts}>
        <Container>
          <h2><span className="fw-bold">Unsere Produkte sind 100% nachhaltig,</span><br />nicht teuer und ohne Kompromisse.</h2>
        </Container>
  		</div>
      <IconSection background={false} firstTitle="4.9" firstText={<>> 500 positive<br /> Rückmeldungen</>} firstStar={true} secondTitle="0%" secondText={<>Plastik<br /> innen & außen</>} thirdTitle="100%" thirdText={<>Made in Germany<br /> & Made by Us</>} fourthTitle="2x" fourthText={<>alle Sorten mit und<br /> ohne Fluorid</>} />
      <div className={styles.ingredientsSection}>
        <Container>
          <Row className={styles.stickyIngredients}>
            <Col lg={7} className={styles.ingredientsText}>
              <h2>Unsere Inhaltsstoffe</h2>
              <p className={styles.subtitle}>Nur das Beste für dich und deinen Körper, nichts schlechtes.</p>
              <p className={styles.description}>Wann hast du das letzte Mal die Inhaltsstoffe auf deiner Zahnpastatube gelesen? Billige Zusatzprodukte, Mikroplastik und chemische Zusätze haben in Zahnpasta und in unserem Körper nichts verloren, <span className="fw-bold">vor allem nicht jeden Tag mindestens zweimal täglich.</span> Deshalb haben wir alles Schlechte entfernt und nur das Gute übrig gelassen. Unsere Inhaltsstoffe gepresst in der Form unserer Drops.</p>
            </Col>
            <Col lg={5}>
              <Row className={styles.listRow}>
                <Col md={9} xs={9}>
                  <p className={styles.listHl}>Xylitol</p>
                  <p>Weist kariesverursachende Bakterien ab und ist natürlich süß.</p>
                </Col>
                <Col md={3} xs={3}>
                  <GatsbyImage image={data.xylithImg.childImageSharp.gatsbyImageData} alt="Xylitol" />
                </Col>
              </Row>
              <Row className={styles.listRow}>
                <Col md={9} xs={9}>
                  <p className={styles.listHl}>Calcium Carbonate</p>
                  <p>Reinigt, desodoriert und schützt Zähne und Mund.</p>
                </Col>
                <Col md={3} xs={3}>
                  <GatsbyImage image={data.ccImg.childImageSharp.gatsbyImageData} alt="Calcium Carbonate" />
                </Col>
              </Row>
              <Row className={styles.listRow}>
                <Col md={9} xs={9}>
                  <p className={styles.listHl}>Cyamopsis Tetragonoloba Gum</p>
                  <p>Natürliches Bindungsmittel für deine Drops.</p>
                </Col>
                <Col md={3} xs={3}>
                  <GatsbyImage image={data.guakaImg.childImageSharp.gatsbyImageData} alt="Cyamopsis Tetragonoloba Gum" />
                </Col>
              </Row>
              <Row className={styles.listRow}>
                <Col md={9} xs={9}>
                  <p className={styles.listHl}>Sodium Bicarbonate</p>
                  <p>Verbessert den Glanz der Zähne und desodoriert.</p>
                </Col>
                <Col md={3} xs={3}>
                  <GatsbyImage image={data.natronImg.childImageSharp.gatsbyImageData} alt="Sodium Bicarbonate" />
                </Col>
              </Row>
              <Row className={styles.listRow}>
                <Col md={9} xs={9}>
                  <p className={styles.listHl}>Sodium Lauroyl Glutamate</p>
                  <p>Bildet Schaum und formt eine angenehme Paste.</p>
                </Col>
                <Col md={3} xs={3}>
                  <GatsbyImage image={data.slgImg.childImageSharp.gatsbyImageData} alt="Sodium Lauroyl Glutamate" />
                </Col>
              </Row>
              <Row className={styles.listRow}>
                <Col md={9} xs={9}>
                  <p className={styles.listHl}>Microcrystalline Cellulose</p>
                  <p>Natürliches Bindungsmittel für deine Drops.</p>
                </Col>
                <Col md={3} xs={3}>
                  <GatsbyImage image={data.mccImg.childImageSharp.gatsbyImageData} alt="Microcrystalline Cellulose" />
                </Col>
              </Row>
              <Row className={styles.listRow}>
                <Col md={9} xs={9}>
                  <p className={styles.listHl}>Mentha Arvensis Leaf Oil</p>
                  <p>Natürliche Ackerminze für einen frischen Geschmack.</p>
                </Col>
                <Col md={3} xs={3}>
                  <GatsbyImage image={data.mintImg.childImageSharp.gatsbyImageData} alt="Mentha Arvensis Leaf Oil" />
                </Col>
              </Row>
              <Row className={styles.listRow}>
                <Col md={9} xs={9}>
                  <p className={styles.listHl}>Silica</p>
                  <p>Reinigt, desodoriert und schützt Zähne und Mund.</p>
                </Col>
                <Col md={3} xs={3}>
                  <GatsbyImage image={data.sioImg.childImageSharp.gatsbyImageData} alt="Silica" />
                </Col>
              </Row>
              <Row className={styles.listRow}>
                <Col md={9} xs={9}>
                  <p className={styles.listHl}>Menthol</p>
                  <p>Natürliches Menthol sorgt für eine langanhaltende Frische.</p>
                </Col>
                <Col md={3} xs={3}>
                  <GatsbyImage image={data.mentholImg.childImageSharp.gatsbyImageData} alt="Menthol" />
                </Col>
              </Row>
              <Row className={styles.listRow}>
                <Col md={9} xs={9}>
                  <p className={styles.listHl}>Mentha Pipertia Leaf Oil</p>
                  <p>Pfefferminze für einen ausgewogenen Geschmack.</p>
                </Col>
                <Col md={3} xs={3}>
                  <GatsbyImage image={data.mintImg.childImageSharp.gatsbyImageData} alt="Mentha Pipertia Leaf Oil" />
                </Col>
              </Row>
              <Row className={styles.listRow}>
                <Col md={9} xs={9}>
                  <p className={styles.listHl}>Stevia Rebaudiana Extract</p>
                  <p>Natürliches Süßungsmittel für guten Geschmack.</p>
                </Col>
                <Col md={3} xs={3}>
                  <GatsbyImage image={data.steviaImg.childImageSharp.gatsbyImageData} alt="Stevia Rebaudiana Extract" />
                </Col>
              </Row>
              <Row className={styles.listRow}>
                <Col md={9} xs={9}>
                  <p className={styles.listHl}>Sodium Fluoride</p>
                  <p>Schützt vor Karies und Zahnbelag.</p>
                </Col>
                <Col md={3} xs={3}>
                  <GatsbyImage image={data.fluoridImg.childImageSharp.gatsbyImageData} alt="Sodium Fluoride" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.sustainability}>
        <h2>Kein Plastik, nirgendwo.<br />Auch nicht beim Versand.</h2>
        <img src={deliveryGIF} className={`w-50 ${styles.sustainabilityGif}`} alt="Nachhaltiger Versand" />
        <div className={styles.keyPoints}>
          <div className={styles.keyPoint}>
            <div className={styles.keyIcon}>
              <div className={styles.dotContainer}><div className={styles.greenDot}><span></span></div></div>
            </div>
            <p>Drops kommen in kompostierbaren Beuteln</p>
          </div>
          <div className={styles.keyPoint}>
            <div className={styles.keyIcon}>
              <div className={styles.dotContainer}><div className={styles.greenDot}><span></span></div></div>
            </div>
            <p>Versandverpackungen aus Recyclingpappe</p>
          </div>
          <div className={styles.keyPoint}>
            <div className={styles.keyIcon}>
              <div className={styles.dotContainer}><div className={styles.greenDot}><span></span></div></div>
            </div>
            <p>Zugestellt per Fahrrad dank Briefversand</p>
          </div>
        </div>
      </div>
      <div className={styles.teamSection}>
        <Container>
          <h2>Unser Team</h2>
          <p>Du möchtest uns kontaktieren?<br /><a href="mailto:info@toothpastedrops.com">Schick uns eine Email</a> oder <a href="https://wa.me/4915735988383?text=Hallo ich hätte eine Frage: ">eine WhatsApp</a>!</p>
          <Row className={`${styles.memberSection} justify-content-md-center`}>
            <GatsbyImage image={data.team.childImageSharp.gatsbyImageData} alt="Drops Team" />
            <p className={styles.imgSubtitle}>"Im Labor beim Experimentieren neuer Sorten (v.l.n.r. Florian, Iman, Fabian, die drei Gründer von Toothpaste Drops)"</p>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}
